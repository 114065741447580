@use "../../utils/variables" as v;
section.contact-us-area{
    padding: 50px 100px;
}
.contact-us-area{
    position: relative;
    /*min-height: 100vh;*/
    padding: 50px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;
    & > .contact-content{
        max-width: 800px;
        text-align: center;
        & > h2{
            font-size: 36px;
            font-weight: 500;
            color: v.$colorB;
            text-shadow: 0px 0px 6px v.$colorD,
            0px 0px 3px v.$colorC, 
            0px 0px 3px v.$colorC, 
            0px 0px 3px v.$colorC, 
        }
        & > p{
            font-weight: 400;
            color: v.$colorA;
            text-shadow: 0px 0px 5px v.$colorD;
        }
    }
    background-color: #00000055;
}
.container{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    & > .contactInfo{
        display: flex;
        flex-direction: column;
        & > .box{
            position: relative;
            padding: 20px 0;
            display: flex;
            & > .icon{
                min-width: 60px;
                height: 60px;
                background: v.$colorA;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 22px;
            }
            & > .text{
                display: flex;
                margin-left: 20px;
                font-size: 16px;
                color: v.$colorA;
                flex-direction: column;
                font-weight: 400;
                text-shadow: 0px 0px 5px v.$colorD;
                & > h3{
                    font-weight: 500;
                    color: v.$colorB;
                    text-shadow: 0px 0px 6px v.$colorD,
                    0px 0px 3px v.$colorC, 
                    0px 0px 3px v.$colorC, 
                    0px 0px 3px v.$colorC,
                }
                & > h4{
                    font-weight: 400;
                    color: v.$colorD;
                    text-shadow: 0px 0px 4px v.$colorD,
                    0px 0px 2px v.$colorC, 
                    0px 0px 2px v.$colorC, 
                    0px 0px 2px v.$colorC,
                }
            }
        }
    }
}
div.contactForm{
    padding: 40px;
}
.contactForm{
    padding: 40px;
    width: 100%;
    background-color: v.$backgroundATXL;
    & h2{
        font-size: 30px;
        color: v.$colorC;
        /*text shadow*/
        text-shadow: 0px 0px 3px v.$colorB,
        0px 0px 2px v.$colorB,
        0px 0px 1px v.$colorB;
        font-weight: 500;
    }
    & .inputBox{
        position: relative;
        width: 100%;
        margin-top: 10px;
        &.error{
            & input{
                border: 3px solid red;
            }
        }
        & input, & textarea{
            width: 100%;
            padding: 5px 10px;
            font-size: 16px;
            margin: 10px 0;
            border: none;
            color: v.$colorD;
            border-bottom: 2px solid v.$colorC;
            outline: none;
            background-color: v.$backgroundATL;
            resize: none;
        }
        & span{
            position: absolute;
            left: 0;
            top: 0;
            padding: 5px 10px;
            font-size: 16px;
            margin: 10px 0;
            pointer-event: none;
            transition: 0.5s;
            color: #666;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
        & input:focus ~ span, & input:valid ~ span, & textarea:focus ~ span, & textarea:valid ~ span{
            color: v.$colorC;
            text-shadow: 0px 0px 1px v.$colorB;
            font-size: 12px;
            transform: translateY(-20px);
            & > span{
                color: v.$colorC;
                text-shadow: 0px 0px 1px v.$colorB;
                font-size: 12px;
                transform: translateY(-20px);
            }
            &.error-msg{
                color: red;
                text-shadow: 0px 0px 1px #ff3333;
                margin-left:4em;
                display: inline;
            }
        }
        & input[type="submit"]{
            width: 100%;
            /*background: v.$colorC;*/
            background: v.$backgroundDTS;
            box-shadow: 0px 0px 2px v.$colorB;
            color: v.$colorA;
            border: none;
            cursor: pointer;
            padding: 10px;
            font-size: 18px;
            &:hover{
                background: v.$colorCTL;
                background: v.$backgroundDTL;
            }
        }
    }
}

.error-msg{
    color: red;
    font-size: 0.8rem;
    display: none;
}
.info-msg{
    color: black;
    font-size: 0.8rem;
}
.secure-msg{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: v.$colorC;
}

@media screen and (min-width: 320px) {
    section.contact-us-area{
        padding: 10px 20px;
    }
}
@media screen and (min-width: 481px) {
    
}
@media screen and (min-width: 641px) {
    
}
@media screen and (min-width: 981px) {
    .container{
        flex-direction: row;
        & > .contactInfo{
            width: 50%;
        }
    }
    .contactForm{
        width: 40%;
    }
}
@media screen and (min-width: 1025px) {

}
@media screen and (min-width: 1281px) {
    
}
.phone-title{
    font-weight: 350;
    color: v.$colorB;
    text-shadow: 0px 0px 6px v.$colorD,
        0px 0px 3px v.$colorC, 
        0px 0px 3px v.$colorC, 
        0px 0px 3px v.$colorC;
}